<template>
  <div class="layout-root">
    <div
      v-if="theme === 'subpage'"
      class="background"
      @click="navigateTo(ROUTE.SETTINGS__INTEGRATIONS__SOURCES)"
    />

    <div class="layout-root__menu">
      <ReflectTitle
        class="layout-root__title"
        :text="$t('settings.title')"
      />
      <SettingsNavMenu v-if="theme !== 'subpage'" />
    </div>

    <div class="layout-root__container">
      <div
        class="container__page"
        :class="{
          'container__page--full-page-width': theme === 'subpage'
        }"
      >
        <slot />
      </div>
    </div>

    <Teleport
      v-if="isRouteChangeConfirmationModalOpen"
      to="#action"
    >
      <ReflectModal
        :title-message="$t('navigationModal.title')"
        :action-message="$t('navigationModal.action')"
        :cancel-message="$t('navigationModal.cancel')"
        action-button-theme="quaternary"
        @action="confirmRouteChange"
        @cancel="abortRouteChange"
      >
        <p class="edition-exit-modal">
          {{ $t('navigationModal.description1') }}
        </p>
        <p class="edition-exit-modal">
          {{ $t('navigationModal.description2') }}
        </p>
      </ReflectModal>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import type { SlotsType } from 'vue'
import { ROUTE } from '~/constants/routes'
import { useLayoutStore } from '~/stores/layout'
import { useRouterStore } from '~/stores/router'

export default {
  name: 'SettingsLayout',
  slots: Object as SlotsType<{
    default: {}
  }>,
  setup() {
    return { ROUTE }
  },
  computed: {
    ...mapState(useLayoutStore, ['theme']),
    ...mapState(useRouterStore, ['isRouteChangeConfirmationModalOpen'])
  },
  methods: {
    confirmRouteChange() {
      const { confirmRouteChange } = useRouterStore()
      confirmRouteChange()
    },
    abortRouteChange() {
      const { abortRouteChange } = useRouterStore()
      abortRouteChange()
    }
  }
}
</script>

<style lang="scss" scoped>
$menu-width: 320px;
$content-margin: 80px;

.layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &__menu {
    position: absolute;
    left: calc(50% - ($page-width / 2));
    bottom: $margin-medium;
    display: flex;
    flex-direction: column;
    width: $menu-width;
    height: calc(100% - $margin-medium - $margin-regular);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: $margin-regular;
    padding-bottom: $margin-medium;
    box-sizing: border-box;
    overflow: hidden auto;

    .container {
      &__page {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc($page-width - $menu-width - $content-margin);
        transform: translateX(calc(($menu-width + $content-margin) / 2));
        // Align this div with nav menu (= title size + bottom margin)
        margin-top: calc(56px + $margin-regular);

        &--full-page-width {
          width: $page-width;
          transform: unset;
          flex: 1;
        }
      }
    }
  }
}

.edition-exit-modal {
  width: 480px;
  @include font-text;
  @include font-size($font-size-regular);
  color: $text-secondary;
  text-align: center;

  &:first-of-type {
    margin-bottom: $margin-regular;
  }
}

.background {
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100%;
  width: calc(100% + $side-width);
  background-color: $overlay-background;
  cursor: pointer;
}
</style>
